<template>
  <div>
    <v-row>
      <v-col cols="12">
        <ClassStudentInfo />
      </v-col>
      <v-col cols="12">
        <ClassStudentHomeworks />
      </v-col>
      <v-col cols="12">
        <ClassStudentExam />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ClassStudentInfo from "@/packages/admin/components/classRoom/details/ClassStudentInfo";
import ClassStudentExam from "@/packages/admin/components/classRoom/details/ClassStudentExams";
import ClassStudentHomeworks from "@/packages/admin/components/classRoom/details/ClassStudentHomeworks";
export default {
  name: "ClassDetailsExams",
  components: { ClassStudentHomeworks, ClassStudentExam, ClassStudentInfo },
};
</script>

<style scoped></style>
